import { makeStyles, Theme } from '@bamboohr/fabric';

export const useStyles = makeStyles<Theme, { bannerOffset: number }>(
	(theme: Theme) => {
		const { borderRadiuses, constructs, mixins, palette } = theme;

		return {
			root: {},
			loader: {
				alignSelf: 'center',
				padding: '42px 0',
			},
			results: {
				border: `1px solid ${constructs.border.neutral.weak}`,
				borderRadius: borderRadiuses[200],
				boxShadow: mixins.boxShadow('700', palette.gray[900]),
				maxHeight: ({ bannerOffset }) =>
					`calc(100vh - var(--fabric-global-header-height, 91px) - ${bannerOffset}px)`,
				width: 380,
				backgroundColor: palette.common.white,
				display: 'flex',
				flexDirection: 'column',
				flexShrink: 1,
				overflow: 'hidden',
				zIndex: 31,
			},
		};
	},
);
