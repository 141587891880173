import { ReportResultData } from '../types';

export function getReportUrl(data: ReportResultData): string {
	if (data.url) {
		return data.url;
	}

	// TODO: Escape the ID so it is safe (this is done in `global_search.html.twig`)
	if (data.newFramework) {
		return `/reports/custom/${data.slug}/${data.id}`;
	}

	// TODO: Escape the ID so it is safe (this is done in `global_search.html.twig`)
	return `/reports/report.php?id=${data.id}`;
}
