import React from 'react';
import { Banner, Link } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import {
	PaymentMethodExpiresBannerProps,
	PaymentMethodExpiresTypes,
} from './types';

export function PaymentMethodExpiresBanner({
	type,
}: PaymentMethodExpiresBannerProps) {
	const messages = {
		[PaymentMethodExpiresTypes.ShowPaymentMethodExpiresSoonBanner]: $._(
			'Your Primary Payment Method is expiring soon. Please [go to billing] to update your primary payment method to avoid missing a payment.',
		),
		[PaymentMethodExpiresTypes.ShowPaymentMethodExpiresBeforePaymentBanner]:
			$._(
				'Your Primary Payment Method will expire before your next payment is due. Please [go to billing] to update your primary payment method to avoid missing a payment.',
			),
		[PaymentMethodExpiresTypes.ShowPaymentMethodExpiredBanner]: $._(
			'Your Primary Payment Method has expired. Please [go to billing] to update your primary payment method to avoid missing a payment.',
		),
	};

	return (
		<Banner
			icon={
				type === PaymentMethodExpiresTypes.ShowPaymentMethodExpiredBanner
					? 'circle-exclamation-solid'
					: 'triangle-exclamation-solid'
			}
			type={
				type === PaymentMethodExpiresTypes.ShowPaymentMethodExpiredBanner
					? 'error'
					: 'warning'
			}
		>
			<Message
				link={(text) => (
					<Link
						biId="expiration-banner-go-to-billing-link"
						color="inverted"
						href="/app/settings/account/billing"
					>
						{text}
					</Link>
				)}
				text={messages[type]}
			/>
		</Banner>
	);
}
