import React from 'react';
import { ActionBanner, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import { SetupSummaryBannerProps } from './types';

export function PayrollRegistrationBanner(props: SetupSummaryBannerProps) {
	const { biId, onClose, progress, progressNote, title, subtitle } = props;

	let note = '';
	if (progress && progressNote) {
		note = `${progress.current}/${progress.total} ${progressNote}`;
	}

	return (
		<ActionBanner
			actions={[
				<Button
					dark
					data-bi-id={biId ? `${biId}-payrollBanner-backToPayroll` : null}
					href="/app/payroll/registration"
					key="primary"
					type="button"
				>
					{$.__('Back To Payroll Registration')}
				</Button>,
				<IconButton
					ariaLabel={$.__('Close Payroll Registration banner.')}
					color="secondary"
					dark
					icon="xmark-solid"
					key="close"
					onClick={onClose}
					type="button"
				/>,
			]}
			ariaLabel={$.__('Payroll Registration banner.')}
			biId={biId}
			image={<IconV2 name="circle-dollar-regular" size={60} />}
		>
			<ActionBanner.Title subTitle={note} superTitle={subtitle} title={title} />
		</ActionBanner>
	);
}
