import React, { ReactNode } from 'react';
import { Header as FabricHeader } from '@bamboohr/fabric';
import { PoMicroFrontend } from 'micro-frontend.react';
import { HelpMenu } from './help-menu';
import { Inbox } from './inbox';

export function getPageActions(canSee, helpCenterLink, learningLink) {
	const actions = [] as ReactNode[];

	if (canSee.inbox) {
		actions.push(<Inbox />);
	}

	if (canSee.help) {
		actions.push(
			<HelpMenu helpCenterLink={helpCenterLink} learningLink={learningLink} />,
		);
	}

	if (canSee.settings) {
		actions.push(
			<FabricHeader.Action
				active={!!window.location.href.match(/\.com(\/app)?\/settings/)}
				activeIcon={'gear-solid'}
				data-bi-id="header-settings-link"
				href="/settings/"
				icon={'gear-regular'}
				key="settings"
				label="Settings"
			/>,
		);
	}

	if (canSee.answers) {
		actions.push(<PoMicroFrontend route='/answers/header-button' />);
	}

	return actions;
}
