import {
	JobCandidateResultData,
	SearchResultData,
	SearchResultType,
} from '../../types';

export function isJobCandidateType(
	data: SearchResultData,
): data is JobCandidateResultData {
	return data.type === SearchResultType.JobCandidate;
}
