import classNames from 'classnames';
import { BodyText, Headline, LayoutBox } from '@bamboohr/fabric';
import { bem } from '../../constants';

export function LoginForm(props) {
	const {
		children,
		headerText,
		instructionText,
		userId,
		username,
	} = props;

	const formClasses = classNames(
		bem.className(),
		'BhrForms',
		'js-lockScreenForm',
	);

	return (
		<form
			className={ formClasses }
			onKeyDown={ (e) => {
				if (e.key === 'Escape') {
					e.stopPropagation();
				}
			} }
			onSubmit={ e => e.preventDefault() }
			tabIndex="-1"
		>
			<LayoutBox marginBottom={1}>
				<Headline color="neutral-strong" size="small">{ headerText }</Headline>
			</LayoutBox>
			<LayoutBox marginBottom={3}>
				<BodyText color="neutral-extra-strong" justify="center">
					{ instructionText }
				</BodyText>
			</LayoutBox>
			{ children }
			<input type="hidden" id="userid" name="userid" value={ userId } />
			<input type="hidden" id="username" name="username" value={ username } />
		</form>
    );
}
