import { BannerData, SetupType } from './types';
import { LOCAL_STORAGE_KEY_NAME, UPDATE_BANNER_DATA_EVENT } from './constants';
import { differenceInHours } from '@bamboohr/utils/lib/datetime';

export const removeBannerLocalStorage = () => {
	window.localStorage.removeItem(LOCAL_STORAGE_KEY_NAME);
};

export const updateBannerData = (stepKey: BannerData['stepKey']) => {
	document.dispatchEvent(
		new CustomEvent(UPDATE_BANNER_DATA_EVENT, { detail: { stepKey } }),
	);
};

export const getStepUrl = (bannerData: BannerData) => {
	if (bannerData.type === SetupType.QUICKSTART) {
		return `/setup/${SetupType.QUICKSTART}/steps`;
	}

	if (bannerData.type === SetupType.CORE_IMPLEMENTATION) {
		return `/setup/${SetupType.CORE_IMPLEMENTATION}/steps`;
	}

	if (bannerData.type === SetupType.PAYROLL) {
		const { recordId, clientId } = bannerData;

		return recordId && clientId
			? `/registration/payroll/steps?recordId=${recordId}&clientId=${clientId}`
			: '';
	}
};

export const getStepDataUrl = (type, stepKey, bannerData) => {
	if (type === SetupType.PAYROLL) {
		return `/registration/payroll/steps/${stepKey}?clientId=${bannerData.clientId}`;
	}

	return `/setup/${type}/steps/${stepKey}`;
};

export const shouldRenderBanner = (data: any, bannerData: BannerData) => {
	if (!(data && (data.status?.markedComplete || data.steps))) {
		throw new Error('Step data status not found');
	}

	if (bannerData.type === SetupType.PAYROLL) {
		//returns whether selected step is complete
		return !data.steps.includes(
			(step) => step.key === bannerData.stepKey && step.complete,
		);
	}

	return !data.status.markedComplete;
};

export const isExpiredCustomerOnboardingBanner = ({
	timeStamp,
	type,
}: BannerData) => {
	if (type === SetupType.CUSTOMER_ONBOARDING && timeStamp) {
		return differenceInHours(new Date().getTime(), timeStamp) >= 24;
	}

	return true;
};
