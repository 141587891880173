import { SearchResultData } from '../types';
import {
	getReportUrl,
	isEmployeeType,
	isFileType,
	isJobCandidateType,
	isJobOpeningType,
	isReportType,
} from '.';

export function getDestinationUrl(
	result: SearchResultData,
): string | undefined {
	if (isEmployeeType(result)) {
		// TODO: Escape the ID so it is safe (this is already done in `global_search.html.twig`)
		return `/employees/employee.php?clearLooping=true&id=${result.id}`;
	}
	if (isFileType(result)) {
		// TODO: Escape the ID so it is safe (this is done in `global_search.html.twig`)
		return `/files/index.php?id=${result.id}`;
	}
	if (isJobCandidateType(result)) {
		// TODO: Escape the ID so it is safe (this is done in `global_search.html.twig`)
		return `/hiring/candidates/${result.paId}`;
	}
	if (isJobOpeningType(result)) {
		// TODO: Escape the ID so it is safe (this is done in `global_search.html.twig`)
		return `/applicant_tracking/index.php/viewPosition?id=${result.id}`;
	}
	if (isReportType(result)) {
		return getReportUrl(result);
	}
}
