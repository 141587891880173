import { SearchResultType } from '../../types';

const typesByIndex = {
	0: SearchResultType.Employee,
	1: SearchResultType.JobCandidate,
	2: SearchResultType.Report,
	3: SearchResultType.JobOpening,
	4: SearchResultType.File,
};

export const getTypeFromIndex = (index: number) => {
	return typesByIndex[index];
};
