import { getSearchHelpData } from './get-search-help-data';
import { ItemType } from '@bamboohr/fabric';

export const getSearchItems = async (
	searchParam: string,
): Promise<Array<ItemType>> => {
	return await getSearchHelpData(searchParam).then((responses) => {
		return responses.hits.map((hit): ItemType => {
			if (hit.type === 'pendo') {
				return {
					icon: 'circle-play-regular',
					text: hit.name,
					href: `/home/?pendo=${hit.objectID}`,
					value: `pendo-support-${hit.objectID}`,
				};
			} else {
				return {
					icon: 'file-lines-regular',
					text: hit.name,
					href: hit.url,
					value: hit.objectID,
				};
			}
		});
	});
};
