import {
	KeyboardEventHandler,
	KeyboardEvent as ReactKeyboardEvent,
} from 'react';

/**
 * Prevents the default behavior when a key event is triggered and
 * that key is present in the supplied array.
 * @param keyboardKeys An array of keys that should prevent default behavior
 */
export function preventKeys(
	keyboardKeys: Array<string>,
): KeyboardEventHandler<HTMLInputElement> {
	return (event: KeyboardEvent | ReactKeyboardEvent) => {
		if (keyboardKeys.includes(event.key)) {
			event.preventDefault();
		}
	};
}
