import React from 'react';
import { ActionBanner, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import { SetupSummaryBannerProps } from './types';

export function CoreImplementationBanner(props: SetupSummaryBannerProps) {
	const { biId, buttonText, onClose, primaryUrl, subtitle, title } = props;

	let bannerIcon;
	switch (title) {
		case 'Total Rewards':
			bannerIcon = <IconV2 name="award-regular" size={60} />;
			break;
		case 'Project Pay':
			bannerIcon = <IconV2 name="stopwatch-regular" size={60} />;
			break;
		default:
			bannerIcon = <IconV2 name="gauge-high-regular" size={60} />;
	}

	return (
		<ActionBanner
			actions={[
				<Button
					dark
					data-bi-id={
						biId
							? `${biId}-coreImplementationSummaryBanner-backToCoreImplementation`
							: null
					}
					href={primaryUrl}
					key="primary"
					type="button"
				>
					{buttonText}
				</Button>,
				<IconButton
					ariaLabel={$.__('Close Core Implementation banner.')}
					color="secondary"
					dark
					icon="xmark-solid"
					key="close"
					onClick={onClose}
					type="button"
				/>,
			]}
			ariaLabel={$.__('Core Implementation banner.')}
			biId={biId}
			image={bannerIcon}
		>
			<ActionBanner.Title subTitle={subtitle} title={title} />
		</ActionBanner>
	);
}
