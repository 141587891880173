import { useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useAxios, FetchOptions } from './use-axios';

export interface UsePost<T> {
	post: (
		url: string,
		payload: unknown,
		config?: AxiosRequestConfig,
	) => Promise<T>;
	isPosting: boolean;
}

/**
 * @deprecated
 * Use ajax module from BambooHR/utils instead
 */
export const usePost = <T>(options?: FetchOptions): UsePost<T> => {
	const { fetch, isFetching } = useAxios<T>(options);

	const post = useCallback(
		async (url: any, payload: any, config: any) => {
			const result = await fetch((internalConfig) =>
				axios.post<T>(url, payload, { ...internalConfig, ...config }),
			);
			return result.data;
		},
		[fetch],
	);

	return {
		post,
		isPosting: isFetching,
	};
};
