import algoliasearch from 'algoliasearch/lite';

export type SearchResponse = {
	exhaustiveNbHits: boolean;
	hits: Array<HitResult>;
	hitsPerPage: number;
	nbHits: number;
	nbPages: number;
	page: number;
	params: string;
	processingTimeMS: number;
	query: string;
};

export type HitResult = {
	name: string;
	objectID?: string;
	type: string;
	updated: string;
	url?: string;
	body?: string;
};

export const getSearchHelpData = async (
	phrase: string,
): Promise<SearchResponse> => {
	const client = algoliasearch(
		'LOK4TOOGPX',
		'3e4a64f1e440458ae8a07a3027f805ce',
	);
	const index = client.initIndex('Help Center');

	return await index.search(phrase, { hitsPerPage: 10 });
};
