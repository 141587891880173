import {
	BodyText,
	Button,
	StandardModal,
	StandardModalProps,
	TextButton,
} from '@bamboohr/fabric';
import React from 'react';

export function DisabledForPreviewingModal(props: StandardModalProps) {
	return (
		<StandardModal {...props}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton key="cancel-button" onClick={props.onRequestClose}>
								{$.__('Cancel')}
							</TextButton>,
							<Button key="ok-button" onClick={props.onRequestClose}>
								{$.__('Ok')}
							</Button>,
						]}
					/>
				}
				renderHeader={
					<StandardModal.Header title={$.__('Disabled for Previewing')} />
				}
			>
				<StandardModal.Constraint
					spacingOverrides={{
						top: StandardModal.Constraint.Spacing.MEDIUM,
						bottom: StandardModal.Constraint.Spacing.MEDIUM,
					}}
				>
					<BodyText>
						{$.__(
							'This action would be available to this user. It has been disabled while you preview.',
						)}
					</BodyText>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
