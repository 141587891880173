import { useEffect, useState } from 'react';
import { usePost } from './hooks';
import { getSearchResults } from './etc';
import { SearchResponseData, SearchResultData } from './types';

// TODO: replace use of usePost
export function useSearchApi(searchText: string) {
	const { isPosting, post } = usePost<SearchResponseData>();
	const [searchResults, setSearchResults] = useState<SearchResultData[]>([]);

	useEffect(() => {
		searchGlobal(searchText, post).then((data) => {
			setSearchResults(data);
		});
	}, [searchText]);

	return {
		isPosting,
		results: searchResults,
	};
}

export async function searchGlobal(searchText: string, post) {
	if (typeof searchText !== 'string' || searchText.trim() === '') {
		return [];
	}

	const formData = new FormData();
	formData.set('q', searchText);

	const data = await post('/ajax/globalsearch.php?format=json', formData);
	return getSearchResults(data);
}
