import { addPhotoUrl } from './add-photo-url';
import { cleanUpKeys } from './clean-up-keys';
import { SearchResultType } from '../../types';

export const mapSearchResult = (
	type: SearchResultType,
	employeePhotoUrls: Record<string, string>,
) => {
	return (item: Record<string, unknown>) => {
		let result = { ...cleanUpKeys(item), type };

		if (type === SearchResultType.Employee) {
			result = addPhotoUrl(result, employeePhotoUrls);
		}

		return result;
	};
};
