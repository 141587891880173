import {
	getFormPayRollDetails,
} from 'Form.util';

const TZ_ENDPOINT = '/location/getTimezoneFromZip/%1$s';

function _getGroupField(selector) {
	return $(this).closest('.fieldGroup')
		.find(selector);
}
/**
 * CountryStateZip class
 *
 * Handles changing the country in conjunction with the state input/select as well as the text for the state and zip
 */
class CountryStateZip {
	/**
	 * Construct
	 */
	constructor() {
		this.stateCurValues = {};
		this.classlist = 'state-field state short';
		this.selectors = {
			end: '"]',
			country: ':input[data-csz^="country"], ba-select[data-csz^="country"]',
			countryStart: ':input[data-csz^="country+',
			countryBaSelectStart: 'ba-select[data-csz^="country+',
			paySchedule: '.js-paySchedule',
			state: ':input[data-csz^="state"], ba-select[data-csz^="state"]',
			stateStart: ':input[data-csz^="state+',
			stateBaSelectStart: 'ba-select[data-csz^="state+',
			stateWrapper: 'js-stateWrapper_',
			traxRequired: '.trax-required.js-country-field',
			zip: ':input[data-csz^="postal_code"]',
			zipStart: ':input[data-csz^="postal_code+',
			tz: ':input[data-csz^="timezone"], ba-select[data-csz^="timezone"]',
		};
	}

	/**
	 * Set event listeners
	 */
	setEventListeners() {
		const that = this;
		const $document = $(document);

		$document.on('ba:selectChange', that.selectors.country, function() {
			window.setTimeout(() => that.changeCountry(this));
		});

		$document.on('ba:selectChange', that.selectors.paySchedule, function() {
			window.setTimeout(() => {
				$(that.selectors.traxRequired).each(function() {
					that.changeCountry(this);
					that.updateCountryList(this);
				});
			});
		});

		$document.on('ba:selectChange', that.selectors.state, function() {
			const id = this.dataset.csz.split('+')[1];
			window.setTimeout(() => {
				that.stateCurValues[id] = getJadeCSZValue(id);
			});
		});

		$document.on('change', that.selectors.zip, function() {
			const $tz = this::_getGroupField(that.selectors.tz);

			if ($tz.length < 1) {
				return;
			}

			that.getTimezone(this.value)
				.done(({
					success,
					error,
					timezone_value,
				}) => {
					if (!success) {
						console.error(error);
						return;
					}

					$tz.val(timezone_value)
						.trigger('liszt:updated');
				});
		});

		$(that.selectors.country).observe(function() {
			if (this.dataset.csz.indexOf(':idx:') > -1 || this.dataset.observe === 'done') {
				return;
			}
			const id = this.dataset.csz.split('+')[1];

			this.dataset.observe = 'done';

			window.setTimeout(() => {

				that.stateCurValues[id] = getJadeCSZValue(id);
				that.updateCountryList(this);
				that.changeCountry(this);
			});
		});
	}

	getPayRollDetails(countryHTMLElement) {
		const $element = $(countryHTMLElement);
		const onPowerEdit = $element.closest('.js-PowerEditForm').length > 0;
		const restrictCountry = countryHTMLElement.classList.contains('trax-required') || onPowerEdit;
		return (!restrictCountry) ? {} : getFormPayRollDetails($element, true);
	}

	updateCountryList(countryHTMLElement) {
		const that = this;
		const id = countryHTMLElement.dataset.csz.split('+')[1];

		let countryRestrictionParams = '';

		if (countryHTMLElement.classList.contains('trax-required')) {
			const {
				employeeId = null,
				payScheduleId = null,
			} = this.getPayRollDetails(countryHTMLElement);

			if (payScheduleId !== null) {
				countryRestrictionParams = `payScheduleId=${ payScheduleId }`;
			} else if (employeeId !== null) {
				countryRestrictionParams = `employeeId=${ employeeId }`;
			}
		}
		$.getJSON(
			`/ajax/get_countries?${ countryRestrictionParams }`,
			function(response) {
				if (response.success) {
					const $countryWrapper = $(countryHTMLElement);
					that.changeCountryElement($countryWrapper, id, response).then(({ input, value }) => {
						checkForRequireWhenVisible(input[0]);

						const option = input[0].querySelector(`ba-option[value='${ value }']`);

						if (option) {
							window.setTimeout(() => option.setAttribute('selected', true));
						}
					});
				}
			}
		);
	}

	/**
	 * Change the country and handle the state field (input/select) and text as well as the zip text
	 *
	 * @param {HTMLElement} countryHTMLElement The country HTML element changed
	 * @returns {boolean}
	 */
	changeCountry(countryHTMLElement) {
		const that = this;
		const id = countryHTMLElement.dataset.csz.split('+')[1];
		let $stateField = $(that.selectors.stateStart + id + that.selectors.end).add(that.selectors.stateBaSelectStart + id + that.selectors.end);
		const $zipField = $(that.selectors.zipStart + id + that.selectors.end);
		const $countryField = getCSZSelect(`${ that.selectors.countryStart }${ id }${ that.selectors.end }, ${ that.selectors.countryBaSelectStart }${ id }${ that.selectors.end }`);
		let countryId = countryHTMLElement.value;

		//If no state and zip field, return because we don't need to do anything.
		if ($stateField.length === 0 && $zipField.length === 0) {
			return;
		}

		//If no country ID, set it to the company's country ID so we can load something in
		if (!countryId) {
			//set the states on load to the company's country id otherwise default to the catch all of 1 (USA)
			if (typeof GLOBAL_COMPANY_COUNTRY_ID !== 'undefined') {
				countryId = GLOBAL_COMPANY_COUNTRY_ID;
			} else {
				countryId = 1;
			}
		}

		//get state/zip info from country ID
		let info = that.getCountryInfo(countryId);
		const stateDataPlaceholder = info.stateLabel;
		const specialLabels = $countryField.data('special-csz-labels');
		if (specialLabels) {
			switch (specialLabels) {
				case 'employee_dependents':
					info = that.getDependentCountryInfo(countryId);
					break;
				case 'employee_contacts':
					info = that.getContactCountryInfo(countryId);
					break;
			}
		}
		const stateText = info.stateLabel;
		const zipText = info.zipLabel;

		let countryRestrictionParams = '';

		if (countryHTMLElement.classList.contains('trax-required')) {
			const {
				employeeId = null,
				payScheduleId = null,
			} = this.getPayRollDetails(countryHTMLElement);

			if (payScheduleId !== null) {
				countryRestrictionParams = `&payScheduleId=${ payScheduleId }`;
			} else if (employeeId !== null) {
				countryRestrictionParams = `&employeeId=${ employeeId }`;
			}
		}
		const url = `/ajax/get_states?country=${ countryId }${countryRestrictionParams}`;

		//Run both zip and state changes after the get request so the zip doesn't update before the state
		$.getJSON(
			url,
			function(response) {
				if (response.success) {
					//change zip placeholder
					if ($zipField.length > 0) {
						that.setZipLabel($zipField, zipText);
						$zipField.removeAttr('aria-label');
					}

					//Change state placeholder and field type
					if ($stateField.length > 0) {
						//Set the data-placeholder before element change to ensure updated data-placeholder
						$stateField.attr('data-placeholder', stateDataPlaceholder);
						$stateField = that.changeStateElement($stateField, id, response)
							.then(({ input, value }) => {
								that.setStateLabel(input, stateText);
								checkForRequireWhenVisible(input[0]);

								if (value) {
									const option = input[0].querySelector(`ba-option[value='${ value }']`);

									if (option) {
										window.setTimeout(() => option.setAttribute('selected', true));
									}
								}
							}).catch(() => null);
					}
				}
			}
		);
	}

	/**
	 * Get the country info
	 *
	 * @param {int} countryId The country's ID
	 * @returns {{zipLabel: string, stateLabel: string}}
	 */
	getCountryInfo(countryId) {
		countryId = parseInt(countryId);
		let stateText = '';
		let zipText = '';
		switch (countryId) {
			case 1://us
			case 0://nothing
				stateText = $.__('State');
				zipText = $.__('ZIP');
				break;
			case 3: // australia
				stateText = $.__('State');
				zipText = $.__('Post Code');
				break;
			case 222://uk
				stateText = $.__('County');
				zipText = $.__('Post Code');
				break;
			case 157://nigeria
				stateText = $.__('State');
				zipText = $.__('Postal Code');
				break;
			default:
				stateText = $.__('Province');
				zipText = $.__('Postal Code');
				break;
		}
		return {
			zipLabel: zipText,
			stateLabel: stateText
		};
	}

	/**
	 * Get the country info for dependents when showing the label above the input (special case)
	 *
	 * @param {int} countryId
	 * @returns {{zipLabel: string, stateLabel: string}}
	 */
	getDependentCountryInfo(countryId) {
		countryId = parseInt(countryId);
		let stateText = '';
		let zipText = '';
		switch (countryId) {
			case 1://us
			case 0://nothing
				stateText = $.__('Dependent State');
				zipText = $.__('Dependent ZIP Code');
				break;
			case 3: // australia
				stateText = $.__('Dependent State');
				zipText = $.__('Dependent Post Code');
				break;
			case 222://uk
				stateText = $.__('Dependent County');
				zipText = $.__('Dependent Post Code');
				break;
			case 157://nigeria
				stateText = $.__('Dependent State');
				zipText = $.__('Dependent Postal Code');
				break;
			default:
				stateText = $.__('Dependent Province');
				zipText = $.__('Dependent Postal Code');

				break;
		}
		return {
			zipLabel: zipText,
			stateLabel: stateText
		};
	}

	/**
	 * Get the country info for contacts when showing the label above or to the side of the input (special case)
	 *
	 * @param {int} countryId
	 * @returns {{zipLabel: string, stateLabel: string}}
	 */
	getContactCountryInfo(countryId) {
		countryId = parseInt(countryId);
		let stateText = '';
		let zipText = '';
		switch (countryId) {
			case 1://us
			case 0://nothing
				stateText = $.__('Emergency Contact State');
				zipText = $.__('Emergency Contact ZIP Code');
				break;
			case 3: // australia
				stateText = $.__('Emergency Contact State');
				zipText = $.__('Emergency Contact Post Code');
				break;
			case 222://uk
				stateText = $.__('Emergency Contact County');
				zipText = $.__('Emergency Contact Post Code');
				break;
			case 157://nigeria
				stateText = $.__('Emergency Contact State');
				zipText = $.__('Emergency Contact Postal Code');
				break;
			default:
				stateText = $.__('Emergency Contact Province');
				zipText = $.__('Emergency Contact Postal Code');

				break;
		}
		return {
			zipLabel: zipText,
			stateLabel: stateText
		};
	}

	/**
	 * Fetch the timezone from the given zip code
	 *
	 * @param {String}  zipCode     The zip code set by the user
	 * @return {jQuery.Deferred}    the jQuery promise from the ajax request
	 */
	getTimezone(zipCode) {
		const url = window.sprintf(TZ_ENDPOINT, zipCode);

		return $.getJSON(url)
			.fail((xhr, status, err) => {
				console.error(err);
			});
	}


	/**
	 * Set the label on the zipcode/post code/postal code field
	 *
	 * @param {wrapper} $zipField The jQuery wrapped zip field element
	 * @param {String}  zipText   The text for the label
	 */
	setZipLabel($zipField, zipText) {
		let $zipLabel = $zipField.parent('.fieldDiv').parent('.fieldBox').children('label');

		if ($zipLabel.length === 0) {
			$zipLabel = $zipField.closest('.fab-FormColumn').children('.fab-Label');
		}

		if ($zipLabel.length > 0) {
			$zipLabel.text(zipText);
		} else {
			$zipLabel = $zipField.siblings('label.placeholder');
			if ($zipLabel.length > 0) {
				$zipLabel.text(zipText);
				$zipField.attr('placeholder', '');
			} else {
				const $powerEdit = $zipField.parent('td');
				if ($powerEdit.length > 0) {
					$powerEdit.parent('tr:not([id])').children('th').text(zipText);
				} else {
					$zipField.attr('placeholder', zipText);
				}
			}
		}
	}

	/**
	 * Set the label on the state field
	 *
	 * @param {wrapper} $stateField The jQuery wrapped state field element
	 * @param {String}  stateText   The text for the label
	 */
	setStateLabel($stateField, stateText) {
		//Remove the existing placeholder if found so the field can re-add it
		$stateField.siblings('label.placeholder').remove();

		let $stateLabel = $stateField.parent('.fieldDiv').parent('.fieldBox').children('label');

		if ($stateLabel.length === 0) {
			$stateLabel = $stateField.closest('.fab-FormColumn').children('.fab-Label');
		}

		if ($stateLabel.length > 0) {
			$stateLabel.text(stateText);
		} else {
			const $powerEdit = $stateField.parent('td');
			if ($powerEdit.length > 0) {
				$powerEdit.parent('tr:not([id])').children('th').text(stateText);
			} else if ($stateField[0].nodeName.toLowerCase() === 'input') {
				//Only set the placeholder if the field is an input and none of the other label types were found
				$stateField.attr('placeholder', stateText);
			}
		}
	}

	changeCountryElement($countryWrapper, id, response) {
		const currentSelectedId = $countryWrapper.find('ba-option:selected').val() || null;

		//Get the attributes on the existing field
		const attrs = {};
		$.each($countryWrapper[0].attributes, function(idx, attr) {
			attrs[attr.nodeName] = attr.nodeValue;
		});

		const countryKeys = Object.keys(response.data);
		let foundSelectedOption = false;

		if (countryKeys.length > 0) {
			const jadeSelect = document.createElement('ba-select');
			// Allow width to inherit if attribute exists
			const selectAttrs = Object.assign(
				{},
				{ width: 7 },
				attrs,
				{ placeholder: attrs['data-placeholder'] || attrs.placeholder }
			);

			// Remove text input attributes
			delete selectAttrs.type;

			const keys = Object.keys(selectAttrs);
			keys.forEach(key => jadeSelect.setAttribute(key, selectAttrs[key]));

			const $jadeSelect = $(jadeSelect);

			$jadeSelect.removeClass((index, css) => (css.match(/\bfab-TextInpu\S+/g) || []).join(' '));

			let newOptions = '';

			for (let j = 0; j < countryKeys.length; ++j) {
				const selected = countryKeys[j] == currentSelectedId ? 'selected="selected"' : '';
				foundSelectedOption = (selected === '') ? foundSelectedOption : true;
				newOptions += `<ba-option value="${ countryKeys[j] }" ${ selected }>${ response.data[countryKeys[j]] }</ba-option>`;
			}
			$jadeSelect.html(newOptions);

			$countryWrapper.replaceWith($jadeSelect);

			$countryWrapper = $jadeSelect;
		}

		let newCountryValue;

		if (countryKeys.length < 2 && !foundSelectedOption) {
			newCountryValue = countryKeys[0];
			$countryWrapper.val(newCountryValue);
			$countryWrapper.removeAttr('data-csz-value');
		}

		return new Promise(resolve => resolve({ input: $countryWrapper, value: newCountryValue }));
	}

	/**
	 * Change the State field element type between input and select depending on what should be shown
	 *
	 * @param {wrapper} $stateField The jQuery wrapped state field element
	 * @param {int}     id          The unique csz value/id
	 * @param {Object}  response    The json response from the server
	 *
	 * @return {wrapper} $stateField The new/updated state field
	 */
	changeStateElement($stateField, id, response) {
		const that = this;

		//Get the attributes on the existing field
		const attrs = {};
		$.each($stateField[0].attributes, function(idx, attr) {
			attrs[attr.nodeName] = attr.nodeValue;
		});
		if ($stateField[0].classList.contains('dynamic-field-react')) {
			return new Promise((resolve, reject) => reject(false));
		}
		attrs.component_id = attrs.id ? attrs.id : attrs.component_id;
		attrs.id = attrs.id ? attrs.id : attrs.component_id;

		if (attrs.component_id == 'undefined') {
			attrs.component_id = undefined;
		}

		const $stateWrapper = attrs.component_id ? $(`.${that.selectors.stateWrapper + attrs.component_id}`) : undefined;
		$stateField = ($stateWrapper && $stateWrapper[0]) ? $stateWrapper : $stateField;

		const stateInputWrapper = document.createElement('div');
		stateInputWrapper.classList.add(that.selectors.stateWrapper + attrs.component_id);
		const stateInputLabel = document.createElement('label');
		if (!attrs.component_id) {
			// Why this weird conditional? Because we have web fields that use this too and I can't fix all of those things right now
			// This lets the add dependent modal have access to this without breaking styles over there while fixing the NHP a11y issues
			stateInputLabel.classList.add('hidden');
		}
		stateInputLabel.classList.add('fab-Label');

		stateInputLabel.setAttribute('for', attrs.component_id);

		if (attrs.class?.includes('required')) {
			stateInputLabel.classList.add('fab-Label--required');
		}

		if (attrs.class?.includes('error')) {
			stateInputLabel.classList.add('fab-Label--error');
		}

		//If dropdown field, add the options, otherwise show the input
		if (response.data.length > 0) {
			const jadeSelect = document.createElement('ba-select');
			stateInputLabel.innerText = attrs['data-placeholder'] || $.__('Province');
			// Allow width to inherit if attribute exists
			const selectAttrs = Object.assign(
				{},
				{ width: 4 },
				attrs,
				{ placeholder: attrs['data-placeholder'] },
			);

			stateInputWrapper.appendChild(stateInputLabel);

			// Remove text input attributes
			delete selectAttrs.type;
			delete selectAttrs.id;

			const keys = Object.keys(selectAttrs);
			keys.forEach(key => jadeSelect.setAttribute(key, selectAttrs[key]));

			stateInputWrapper.appendChild(jadeSelect);
			const $jadeSelect = $(jadeSelect);

			$jadeSelect.removeClass((index, css) => (css.match(/\bfab-TextInpu\S+/g) || []).join(' '));

			let newOptions = '';
			for (let j = 0; j < response.data.length; ++j) {
				const selected = that.stateCurValues[id] == response.data[j].id ? 'selected="selected"' : '';
				newOptions += `<ba-option value="${ response.data[j].id }" ${ selected }>${ response.data[j].name }</ba-option>`;
			}
			$jadeSelect.html(newOptions);

			$stateField.replaceWith(stateInputWrapper);

			$stateField = $(stateInputWrapper);
		} else {
			$stateField.replaceWith(function() {
				const stateInput = document.createElement('input');
				const textInputAttrs = Object.assign({}, attrs, { 'type': 'text', 'autocomplete': '' });
				stateInputLabel.innerText = $.__('Province');
				stateInputWrapper.appendChild(stateInputLabel);
				// Remove ba-select attributes (if they exist)
				// Remove placeholder attribute (gets re-added conditionally later)
				delete textInputAttrs.placeholder;
				delete textInputAttrs.width;

				const keys = Object.keys(textInputAttrs);
				keys.forEach(key => stateInput.setAttribute(key, textInputAttrs[key]));
				stateInput.classList.add('fab-TextInput');

				stateInputWrapper.appendChild(stateInput);

				return stateInputWrapper;
			});
			$stateField = $(that.selectors.stateStart + id + that.selectors.end).add(that.selectors.stateBaSelectStart + id + that.selectors.end);
			$stateField.val(isNaN(that.stateCurValues[id]) ? that.stateCurValues[id] : '');
		}

		//Set a value if the state changes by a method other than a click/keystroke and needs a new value
		const newStateValue = $stateField.data('csz-value');
		if (newStateValue) {
			$stateField.val(newStateValue);
			$stateField.removeAttr('data-csz-value');
		}

		// This will only affect the stateField when it is a text input
		$stateField.trigger('change');

		return new Promise(resolve => resolve({ input: $stateField, value: newStateValue }));
	}
}

function getCSZSelect(selector) {
	return $(`${ selector } select`)
}

function getJadeCSZValue(id) {
	let curState = document.querySelector(`[data-csz^="state+${ id }"]`);

	if (curState) {
		if (curState.nodeName === 'BA-SELECT') {
			curState = curState.querySelector('select');
		}

		return curState ? curState.value : '';
	}
}

/**
 * TODO: Remove this function when we figure out validation with ba-select/jQuery validation
 * Updates required classes on hidden select element inside of BA-SELECT for jQuery validation
 * Used for States and Country selects (options are updated when changing syncing and non-syncing Pay Schedule TRAX)
 *
 * @param {elem} input
 */
function checkForRequireWhenVisible(input) {
	const evalClasses = ['requiredWhenVisible', 'requiredWhenTraxSyncing'];

	evalClasses.forEach((evalClass) => {
		// Updates to the fabric select field have made the input value here a wrapper around the actual select element
		// when hot-swapping components (e.g. changing state values based on country)
		if (input.classList.contains(evalClass) || $(input).find(`.${ evalClass }`).length > 0) {
			const selectElement = input.querySelector('select');

			// add the class to the select if present
			if (selectElement) {
				selectElement.classList.add(evalClass);
			}
		}
	});
}

const CountryStateZipClass = new CountryStateZip();
CountryStateZipClass.setEventListeners();
