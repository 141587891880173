export const LOCAL_STORAGE_KEY_NAME = 'setupSummaryBanner';
export const UPDATE_BANNER_DATA_EVENT = 'updateBannerData';
export const PATH_EXCLUSION_LIST = [
	'/app/setup',
	'/reports',
	'/app/reports',
	'/app/payroll/registration',
];
export const PATH_INCLUSION_LIST = [
	'/reports/payroll-deductions',
	'/reports/pay-info-tab-direct-deposit',
];
