import { useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Fetcher } from './types';
import { getHeaders } from './get-headers';

let token: string | null = null;
export interface TokenResponse {
	token: string;
}

export const setToken = (value: string): void => {
	token = value;
};

export const useAuthenticatedFetch = <T>(
	authenticateWithJwt = false,
	ignoreCsrf = false,
): ((fetcher: Fetcher<T>) => Promise<AxiosResponse<T>>) => {
	const csrfToken = window.CSRF_TOKEN;
	async function fetchToken() {
		const response = await axios.post<TokenResponse>(
			'/auth/token_sessions',
			{},
			{ headers: getHeaders(csrfToken) },
		);
		token = response.data.token;
		return response.data.token;
	}

	async function getToken() {
		if (token) {
			return token;
		}
		const newToken = await fetchToken();
		return newToken;
	}

	const authenticatorWithJwt = async (fetcher) => {
		let result;
		let jwt = await getToken();
		try {
			result = await fetcher({ headers: { Authorization: `Bearer ${jwt}` } });
		} catch (error) {
			if (error.response.status === 401) {
				jwt = await fetchToken();
				result = await fetcher({ headers: { Authorization: `Bearer ${jwt}` } });
			} else {
				throw error;
			}
		}
		return result;
	};

	const authenticator = useCallback(
		async (fetcher: any) => {
			const authenticatorHeaders = ignoreCsrf
				? undefined
				: { headers: getHeaders(csrfToken) };
			return await fetcher(authenticatorHeaders);
		},
		[csrfToken, ignoreCsrf],
	);

	return authenticateWithJwt ? authenticatorWithJwt : authenticator;
};
