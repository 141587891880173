import React, { FC } from 'react';

import {
	createLayoutEscapeHatchStyles,
	Header as FabricHeader,
	HeaderLink as FabricHeaderLink,
	GetStylesObjectType,
	LayoutEscapeHatch,
	makeLayoutEscapeHatchStyles,
	makeStyles,
	StyledBox,
	useTheme,
} from '@bamboohr/fabric';
import { SiteData } from '../types';
import { getPageActions } from './actions/get-header-actions';
import { Logo } from './logo';
import { pathMatchesHeaderLink } from './path-matches-header-link';
import { Search } from './search';

const useStyles = makeStyles(() => ({
	root: {
		'@media print': {
			display: 'none',
		},
	},
	actions: {
		flexShrink: 0,
	},
}));

const getHeaderInnerContainerStyles = makeLayoutEscapeHatchStyles(
	({ layout: { pageWidths }, spacing }) => {
		const scrollbarWidth = 16;

		return createLayoutEscapeHatchStyles({
			boxSizing: 'border-box',
			paddingRight: spacing(2),

			// This hides the gutter when there's not enough room for it.
			[`@container (width < ${
				pageWidths.min + pageWidths.buffer + scrollbarWidth
			}px)`]: { paddingRight: 0 },
		} as { [key: string]: GetStylesObjectType });
	},
);

export const Header: FC<React.PropsWithChildren<SiteData>> = ({
	canSee,
	currentPage,
	logo,
	pages,
	help,
}: SiteData) => {
	const classes = useStyles();

	const nav = pages.map((page) => (
		<FabricHeaderLink
			active={
				currentPage === page.id ||
				pathMatchesHeaderLink(page.url, window.location.pathname)
			}
			data-bi-id={page.biId}
			href={page.url}
			key={page.id}
		>
			{page.title}
		</FabricHeaderLink>
	));

	const header = (
		<FabricHeader
			actions={getPageActions(canSee, help.helpCenterLink, help.learningLink)}
			className={classes.root}
			logo={Logo({ ...logo })}
			nav={nav}
			search={canSee.search && <Search />}
		/>
	);

	const {
		layout: { pageWidths },
	} = useTheme();

	return (
        /* This StyledBox wrapper applies a white background color so that content isn't visible behind the gutter. */
        <StyledBox backgroundColor="neutral-forced-white" width="100%">
            {/* This additional wrapper is necessary to create a gutter next to the vertical scrollbar and allow the gutter to disappear when the minimum width is hit. This prevents unnecessary horizontal scrollbars on small screens. */}
            <LayoutEscapeHatch
				getStyles={getHeaderInnerContainerStyles}
				reason="OTHER_CSS_FEATURE_UNSUPPORTED_BY_FABRIC"
				unsupportedCSSFeature="@container queries"
			>
				{/* This StyledBox wrapper allows the header to be positioned in the center of the page. */}
				<StyledBox
					id="js-SiteHeader"
					marginLeft="auto"
					marginRight="auto"
					maxWidth={`${pageWidths.max}px`}
					width="100%"
				>
					{header}
				</StyledBox>
			</LayoutEscapeHatch>
        </StyledBox>
    );
};
