export function removeNumericKeys(
	obj: Record<string, unknown>,
): Record<string, unknown> {
	return Object.keys(obj).reduce((memo, key) => {
		if (!/^\d+$/.test(key)) {
			memo[key] = obj[key];
		}

		return memo;
	}, {});
}
