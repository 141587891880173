import { useEffect } from 'react';

export function useSearchHotkey(callback: () => void) {
	useEffect(() => {
		const handleKeydown = (event) => {
			if (
				(!document.activeElement || document.activeElement === document.body) &&
				event.key === 's'
			) {
				setTimeout(callback);
			}
		};

		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [callback]);
}
