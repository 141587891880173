import React from 'react';
import { Banner, Link } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { GracePeriodProps } from './types';

export const GracePeriodBanner = ({
	daysLeftUntilSuspend,
}: GracePeriodProps) => {
	const lessThanOneWeekTilSuspension = daysLeftUntilSuspend <= 8;
	const gracePeriodBannerLink = (text: string) => (
		<Link
			color="inverted"
			data-bi-id="grace-period-banner-go-to-billing"
			href="/settings/account/billing"
		>
			{text}
		</Link>
	);

	return (
		<Banner
			icon={
				lessThanOneWeekTilSuspension
					? 'circle-exclamation-solid'
					: 'triangle-exclamation-solid'
			}
			type={lessThanOneWeekTilSuspension ? 'error' : 'warning'}
		>
			{daysLeftUntilSuspend === 1 ? (
				<Message
					link={gracePeriodBannerLink}
					text={$._(
						'Your account will be suspended by the end of the day. Please [go to billing] to review your billing information and make a payment to avoid suspension.',
					)}
				/>
			) : (
				<Message
					link={gracePeriodBannerLink}
					params={[daysLeftUntilSuspend.toString()]}
					text={$._(
						"{1} days until your company's BambooHR account is suspended. Please [go to billing] to review your billing information and make a payment to avoid suspension.",
					)}
				/>
			)}
		</Banner>
	);
};
