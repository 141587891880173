import React from 'react';
import { Banner, BannerLink, BodyText } from '@bamboohr/fabric';
import { CancellationNoticeProps } from './types';
import { Message } from '@bamboohr/utils/lib/message';

export function CancellationNoticeBanner(data: CancellationNoticeProps) {
	return (
		<Banner icon={'bhr-circle-b'}>
			{data.accountDaysLeft ? (
				<>
					{data.accountDaysLeft > 1 ? (
						<BodyText inline>
							<Message
								params={[data.accountDaysLeft]}
								text={$._(
									'**{1} days left** until BambooHR services are cancelled.',
								)}
							/>
						</BodyText>
					) : (
						<BodyText inline>
							<Message
								text={$._(
									'**Today is your last day** before BambooHR services are cancelled.',
								)}
							/>
						</BodyText>
					)}
				</>
			) : (
				<>
					{data.payrollDaysLeft > 1 ? (
						<BodyText inline>
							<Message
								params={[data.payrollDaysLeft]}
								text={$._(
									'**{1} days left** until Payroll services are cancelled.',
								)}
							/>
						</BodyText>
					) : (
						<BodyText inline>
							<Message
								text={$._(
									'**Today is your last day** before Payroll services are cancelled.',
								)}
							/>
						</BodyText>
					)}
				</>
			)}{' '}
			<BannerLink href="/app/settings/cancellation">
				{$.__('Click here for more detail.')}
			</BannerLink>
		</Banner>
	);
}
