import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useDebounce = <Value = unknown>(value: Value, delay = 800) => {
	const [debounced, setDebounced] = useState(value);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDebounce = useCallback(
		debounce((val: Value) => {
			setDebounced(val);
		}, delay),
		[setDebounced],
	);
	useEffect(() => {
		onDebounce(value);
	}, [value, onDebounce]);

	return debounced;
};
