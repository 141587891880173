import {
	camelCaseKeys,
	removeNumericKeys,
} from '../utils';

export function cleanUpKeys(
	obj: Record<string, unknown>,
): Record<string, unknown> {
	return camelCaseKeys(removeNumericKeys(obj));
}
