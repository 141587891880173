export enum SearchResultType {
	Employee = 'employee',
	JobCandidate = 'job-candidate',
	JobOpening = 'job-opening',
	Report = 'report',
	File = 'file',
}

export type SearchResponseData = {
	employeePhotoUrls: Record<string, string>;
	searches: Array<Array<Record<string, unknown>>>;
};

export type SearchResultData = {
	type: SearchResultType;
};

export type JobCandidateResultData = {
	activeCount: string;
	allPositionApplicantIdCount: string;
	applicationAge: string;
	deletedCount: string;
	firstName: string;
	id: string;
	lastName: string;
	paId: string;
	paIds: string;
	positionName: string;
	status: string;
} & SearchResultData;

export type EmployeeResultData = {
	employeeNumber: string;
	firstName: string;
	id: string;
	lastName: string;
	middleName: string;
	nickname: string;
	photoUrl?: string;
	status: string;
} & SearchResultData;

export type FileResultData = {
	fileSectionId: string;
	id: string;
	name: string;
} & SearchResultData;

export type JobOpeningResultData = {
	age: string;
	id: string;
	name: string;
	status: string;
} & SearchResultData;

export type ReportResultData = {
	id: number;
	name: string;
	newFramework?: boolean;
	url: string;
	slug?: string;
} & SearchResultData;
