import { SearchResultData, SearchResultType } from '../../types';

export function sortByType(a: SearchResultData, b: SearchResultData): number {
	const order = [
		SearchResultType.Employee,
		SearchResultType.JobCandidate,
		SearchResultType.JobOpening,
		SearchResultType.Report,
		SearchResultType.File,
	];

	return order.indexOf(a.type) - order.indexOf(b.type);
}
