import React from 'react';
import { Banner } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';

export function SignedInAsBanner() {
	const { firstName, lastName, supportAdmin } = window.SESSION_USER;

	return (
		<Banner icon="circle-user-solid">
			<Message
				params={[window.SESSION_COMPANY.name, `${firstName} ${lastName}`]}
				text={
					supportAdmin
						? $._('You are logged into **{1}**.')
						: $._('You are logged into **{1}** on behalf of **{2}**.')
				}
			/>
		</Banner>
	);
}
