import axios from 'axios';
import { useEffect, useState } from 'react';
import { canUseLocalStorage } from '@bamboohr/utils/lib/localstorage';
import {
	LOCAL_STORAGE_KEY_NAME,
	PATH_EXCLUSION_LIST,
	PATH_INCLUSION_LIST,
} from './constants';
import { BannerData, SetupType } from './types';
import {
	getStepUrl,
	isExpiredCustomerOnboardingBanner,
	removeBannerLocalStorage,
	shouldRenderBanner,
} from './setup-banners.utils';

export function useBannerData() {
	const [bannerData, setBannerData] = useState<BannerData>();

	const handleCustomerOnboardingBannerData = (bannerStorageData: BannerData) => {
		if (isExpiredCustomerOnboardingBanner(bannerStorageData)) {
			removeBannerLocalStorage();
		} else {
			setBannerData(bannerStorageData);
		}
	}

	const handleNonCustomerOnboardingBannerData = (bannerStorageData: BannerData) => {
		const stepUrl = getStepUrl(bannerStorageData);
		if (stepUrl) {
			axios
				.get(stepUrl)
				.then(({ data }) => {
					if (shouldRenderBanner(data, bannerStorageData)) {
						if (bannerStorageData?.stepKey) {
							setBannerData(bannerStorageData);
						}
					} else {
						return false;
					}
				})
				.catch(() => {
					removeBannerLocalStorage();
				});
		}
	}

	useEffect(() => {
		const exclusionMatch = PATH_EXCLUSION_LIST.some(
			(path) => window.location.pathname.indexOf(path) === 0,
		);
		const inclusionMatch = PATH_INCLUSION_LIST.some(
			(path) => window.location.pathname.indexOf(path) === 0,
		);
		const initBanner = inclusionMatch || !exclusionMatch;

		if (canUseLocalStorage() && initBanner) {
			const bannerStorageItem = window.localStorage.getItem(
				LOCAL_STORAGE_KEY_NAME,
			);
			if (bannerStorageItem) {
				const bannerStorageData: BannerData = JSON.parse(bannerStorageItem);
				if (bannerStorageData?.type === SetupType.CUSTOMER_ONBOARDING) {
					handleCustomerOnboardingBannerData(bannerStorageData);
				} else {
					handleNonCustomerOnboardingBannerData(bannerStorageData);
				}
			}
		}
	}, []);
	return { bannerData, setBannerData };
}
