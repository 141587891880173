import React, { FC, ReactElement } from 'react';
import { makeStyles, Svg } from '@bamboohr/fabric';

const useStyles = makeStyles(({ palette }) => ({
	logo: {
		maxWidth: '300px',
		maxHeight: '60px',
	},
	bamboo: {
		fill: palette.primary.main,
	},
}));

type LogoProps = {
	url?: string;
	alt?: string;
	width?: number;
	fallback?: ReactElement;
};

export const Logo: FC<React.PropsWithChildren<LogoProps>> = (props) => {
	const classes = useStyles();
	const { url, alt, width, fallback } = props;

	const defaultLogo = fallback ? (
		fallback
	) : (
		<Svg color="primary-strong" height={28} image="bamboohr-logo" width={190} />
	);

	const logo = (
		<img alt={alt} className={classes.logo} src={url} width={width} />
	);

	return url ? logo : defaultLogo;
};
