import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	header: {
		background: palette.gray[100],
		display: 'flex',
		padding: spacing(1.25, 1),
		width: 308,
	},
	name: {
		display: 'block',
		fontSize: typography.large?.fontSize,
		fontWeight: typography.fontWeightSemibold,
		lineHeight: typography.medium?.lineHeight,
	},
	button: {
		marginTop: spacing(1),
	},
	accountMenu: {
		display: 'flex',
		marginLeft: '20px',
	},
	tinyAvatar: {
		width: '26px',
		height: '26px',
	},
	largeAvatar: {
		width: '72px',
		height: '72px',
		marginRight: spacing(2),
	},
	expired: {
		display: 'flex',
		color: palette.primary.main,
		marginLeft: '20px',
	},
}));
