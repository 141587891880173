import { useEffect, useRef } from 'react';

/**
 * Allows you to easily hook into any escape key press
 * @param callback A callback that is invoked when the escape key is pressed
 * @param condition A flag that indicates when to listen to key events (true starts listening, false stops listening)
 */
export const useEscapeKey = (callback: () => void, condition: boolean) => {
	const ref = useRef<() => void>();
	ref.current = callback;

	useEffect(() => {
		const handleKeydown = (event) => {
			if (typeof ref.current === 'function' && event.key === 'Escape') {
				ref.current();
			}
		};

		if (condition) {
			document.addEventListener('keydown', handleKeydown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [ref, condition]);
};
