export enum SetupType {
	QUICKSTART = 'quickStart',
	PAYROLL = 'payroll',
	CORE_IMPLEMENTATION = 'core',
	CUSTOMER_ONBOARDING = 'customerOnboarding',
}

export interface BannerData {
	type: SetupType;
	stepKey?: string;
	taskKey?: string;
	recordId?: string;
	clientId?: string;
	dataPath?: string;
	biId?: string;
	iconName?: string;
	title: string;
	primaryUrl?: string;
	secondaryUrl?: string;
	progressNote?: string;
	subtitle?: string;
	buttonText?: string;
	timeStamp?: number;
}

export type SetupSummaryBannerProps = Omit<
	BannerData,
	'type' | 'stepKey' | 'taskKey' | 'dataPath'
> & {
	onClose?: () => void;
	progress?: {
		current: number;
		total: number;
	};
};
