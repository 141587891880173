import axios from 'axios';
import React, { useState } from 'react';
import { Banner } from '@bamboohr/fabric';
import { toFormData } from '../utils';
import { AnnouncementBannerProps } from './types';

export function AnnouncementBanner({ id, message }: AnnouncementBannerProps) {
	const [isOpen, setIsOpen] = useState(true);

	function onCloseBanner() {
		axios.post(
			'/ajax/announcement.php',
			toFormData({
				action: 'read',
				id,
			}),
		);
		setIsOpen(false);
	}

	return (
		<Banner icon="bullhorn-solid" onClose={onCloseBanner} open={isOpen}>
			{message}
		</Banner>
	);
}
