import { getTypeFromIndex } from './get-type-from-index';
import { mapSearchResult } from './map-search-result';
import { SearchResponseData, SearchResultData } from '../../types';

export function adaptSearchResults(
	data: SearchResponseData,
): Array<SearchResultData> {
	return data.searches.reduce<Array<SearchResultData>>(
		(memo, search, index) => {
			const type = getTypeFromIndex(index);

			return [
				...memo,
				...search.map(mapSearchResult(type, data.employeePhotoUrls)),
			];
		},
		[],
	);
}
