import React from 'react';
import { GlobalNavigation as FabricGlobalNavigation } from '@bamboohr/fabric';
import { getGlobalNavigationLinks } from './global-nav-links';
import { SiteData } from '../types';
import { AccountMenu } from '../account-menu';

export function GlobalNavigation({
	navigationIsOpen = false,
	onToggle = () => {
		return false;
	},
	siteData = {} as SiteData,
}: {
	onToggle: () => void;
	navigationIsOpen: boolean;
	siteData: SiteData;
}) {
	const { canSee, currentPage, pages } = siteData;

	return (
		<FabricGlobalNavigation
			footer={[<AccountMenu canSee={canSee} key="accountMenuFooterItem" />]}
			links={getGlobalNavigationLinks({ currentPage, pages, navigationIsOpen })}
			onToggle={onToggle}
			open={navigationIsOpen}
		/>
	);
}
