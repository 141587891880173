import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, spacing }) => ({
	icon: {
		'&:hover': {
			fill: palette.primary.main,
		},
		marginLeft: '20px',
		display: 'flex',
	},
	header: {
		background: palette.gray[100],
		display: 'flex',
		padding: spacing(0.25, 2),
		flexGrow: 1,
	},
}));
