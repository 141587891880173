import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { Fetcher, OnAxiosError } from './types';
import { useAuthenticatedFetch } from './use-authenticated-fetch';
import { useSetApiErrorMessage } from './use-set-api-error-message';

export interface UseAxios<ResponseData> {
	fetch: (
		fetcher: Fetcher<ResponseData>,
	) => Promise<AxiosResponse<ResponseData>>;
	isFetching: boolean;
}

export interface FetchOptions {
	initialFetching?: boolean;
	onError?: OnAxiosError;
	authenticateWithJwt?: boolean;
	ignoreCsrf?: boolean;
}

export interface TokenResponse {
	token: string;
}

/**
 * @deprecated
 * Use regular axios module instead
 */
export const useAxios = <ResponseData>(
	options: FetchOptions = {},
): UseAxios<ResponseData> => {
	const {
		initialFetching = false,
		onError,
		authenticateWithJwt = false,
		ignoreCsrf = false,
	} = options;
	const [isFetching, setIsFetching] = useState<boolean>(initialFetching);
	const { setApiErrorMessage, setErrorMessage } = useSetApiErrorMessage();

	const authenticator = useAuthenticatedFetch(authenticateWithJwt, ignoreCsrf);

	const doneFetching = useCallback(
		() =>
			setTimeout(() => {
				setIsFetching(false);
			}),
		[setIsFetching],
	);

	const fetch = useCallback(
		async (
			fetcher: (
				internalConfig?: AxiosRequestConfig,
			) => Promise<AxiosResponse<ResponseData>>,
		): Promise<AxiosResponse<ResponseData>> => {
			setIsFetching(true);
			let result;
			try {
				result = await authenticator(fetcher);
			} catch (err) {
				if (onError) {
					onError(err, setErrorMessage);
				} else {
					setApiErrorMessage(err);
				}
				throw err;
			} finally {
				doneFetching();
			}
			return result;
		},
		[doneFetching, onError, authenticator, setApiErrorMessage, setErrorMessage],
	);

	return {
		fetch,
		isFetching,
	};
};
