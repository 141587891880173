import axios from 'axios';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { UPDATE_BANNER_DATA_EVENT } from './constants';
import {
	getStepDataUrl,
	removeBannerLocalStorage,
} from './setup-banners.utils';
import { BannerData, SetupType } from './types';

export function useStepData(bannerData?: BannerData) {
	const [stepData, setStepData] = useState<object>();

	const getStepData = (e?) => {
		if (bannerData && (!e || e?.detail?.stepKey === bannerData?.stepKey)) {
			const stepDataUrl = getStepDataUrl(
				bannerData.type,
				bannerData.stepKey,
				bannerData,
			);

			if (bannerData.type === SetupType.CORE_IMPLEMENTATION) {
				setStepData({});
			} else {
				axios
					.get(stepDataUrl)
					.then((resp) => {
						const taskData =
							get(resp.data, bannerData.dataPath || '') || resp.data;
						setStepData(taskData);
					})
					.catch(() => {
						removeBannerLocalStorage();
					});
			}
		}
	};

	useEffect(() => {
		if (bannerData?.stepKey) {
			getStepData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerData]);

	useEffect(() => {
		document.addEventListener(UPDATE_BANNER_DATA_EVENT, getStepData);

		return () => {
			document.removeEventListener(UPDATE_BANNER_DATA_EVENT, getStepData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { stepData, setStepData };
}
