import { hasMatch } from './has-match';
import { EmployeeResultData } from '../../../../types';

export const getDisplayName = (
	data: EmployeeResultData,
	searchText = '',
): string => {
	const { employeeNumber, firstName, lastName, middleName, nickname } = data;

	const displayName = [firstName];
	const keywords = searchText.split(' ');

	if (keywords.some((keyword) => hasMatch(nickname, keyword))) {
		displayName.push(nickname);
	}
	if (keywords.some((keyword) => hasMatch(middleName, keyword))) {
		displayName.push(middleName);
	}

	displayName.push(lastName);

	if (
		keywords.some((keyword) => keyword.startsWith('#') || /^\d/.test(keyword))
	) {
		displayName.push(`(${employeeNumber})`);
	}

	return displayName.join(' ').trim();
};
