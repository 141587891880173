import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		paddingTop: 21,
		paddingBottom: 21,
		textAlign: 'center',
		width: '100%',
	},
	icon: {
		fill: palette.gray[600],
		marginBottom: 12,
	},
	text: {
		color: palette.gray[700],
		fontSize: typography.medium?.fontSize,
		lineHeight: typography.medium?.lineHeight,
	},
}));
