import React from 'react';
import { Banner, BodyText, Flex, TextButton } from '@bamboohr/fabric';
import { DemoDataAndDaysInTrialProps } from './types';
import { Message } from '@bamboohr/utils/lib/message';
import { clearDemoData } from '../../../../base/company-data';

export function DemoDataAndDaysInTrialBanner(
	data: DemoDataAndDaysInTrialProps,
) {
	const { isOwner, isAdmin } = window.SESSION_USER;

	const icon = 'bhr-circle-b';

	return (
		<>
			<Banner
				{...(isAdmin && data.status === 'In Trial' ? { icon } : {})}
				type="trial"
			>
				<Flex alignItems="center" justifyContent="space-between">
					{isAdmin && data.status === 'In Trial' && (
						<div>
							<BodyText inline>
								<Message
									params={[data.freeTrialDays]}
									text={$._n(
										`**{1}** day left in your free trial.`,
										`**{1}** days left in your free trial.`,
										data.freeTrialDays,
									)}
								/>
							</BodyText>
							{isOwner && (
								<BodyText component="span" inline weight="bold">
									{' '}
									{$.__('Call %s to sign up!', data.salesNumber)}
								</BodyText>
							)}
						</div>
					)}
					{data.companyMasterIsDemo && (
						<BodyText component="span" icon="eye-solid" inline>
							{$.__('Viewing sample data.')}{' '}
							{data.canClearDemoData && (
								<TextButton
									dark
									data-bi-id="clear-demo-data-link"
									inline
									onClick={clearDemoData}
								>
									{$.__('Clear data and add your own »')}
								</TextButton>
							)}
						</BodyText>
					)}
				</Flex>
			</Banner>
		</>
	);
}
