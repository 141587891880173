import { useEffect } from 'react';
import { ItemType } from '@bamboohr/fabric';
import { getSearchItems } from './get-search-items';

export const useSearchHelpData = (
	searchParam: string,
	setSearchItems: (searchItems: Array<ItemType>) => void,
): void => {
	useEffect(() => {
		getSearchItems(searchParam).then((response) => setSearchItems(response));
	}, [searchParam, setSearchItems]);
};
