import Ajax from '@bamboohr/utils/lib/ajax';

type SubsectionType = {
	count: string;
	href: string;
	label: string;
};

type ItemListData = {
	counts: number;
	success: boolean;
	subsections: Array<SubsectionType>;
	isEmployeeCommunityWidgetEnabled: boolean;
};

export async function getInboxData(): Promise<ItemListData> {
	const response = await Ajax.get('/ajax/inbox/inbox_counts_json.php');
	return response.data;
}
