import { useCallback } from 'react';
import { SearchResultType } from '../types';

export function useGroupLabel(): { getLabel: (type: string) => string } {

	const getLabel = useCallback(
		(type: string): string => {
			const map = {
				[SearchResultType.Employee]: $.__('Employees'),
				[SearchResultType.File]: $.__('Files'),
				[SearchResultType.JobCandidate]: $.__('Job Candidates'),
				[SearchResultType.JobOpening]: $.__('Job Openings'),
				[SearchResultType.Report]: $.__('Reports'),
			};
			return map[type];
		},
		[$.__],
	);

	return {
		getLabel,
	};
}
