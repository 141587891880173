import { isBoolean, isNaN, isNull, isObject, isUndefined, get } from 'lodash';
import Ajax from '@utils/ajax';
import { canUseLocalStorage } from '@utils/localstorage';
import {
	getStoredRememberToken,
	doGetUserMfaData,
	isRememberTokenValid,
	removeStoredRememberToken,
	MFA_JWT_REMEMBER_TOKEN_VALIDITY,
} from 'multi-factor-authentication.mod';
import { attachTokens, getHashCode, getToken, setHashedToken, setToken, updateInput } from 'csrf.util';
import { openLockModal, closeLockModal } from 'lock-modal.mod';
import { GOOGLE_TYPE, PASSWORD_TYPE } from './constants';
import { getLoginType, getLoginService } from './service';
import {handleGhSession} from "../gh-manage-session.mod";

let nextLockCheckTimeout = 0;

window.checkTimeout = checkTimeout;
window.handleLoginResponse = handleLoginResponse;

(function init() {
	if (canUseLocalStorage()) {
		setHashedToken(getToken(), isOnboardingUser());
		window.localStorage.removeItem('bhr_lock_times');

		$(window).on('storage', function (e) {
			const eventKey = e.originalEvent.key;
			const storageKey = `hashed_token${isOnboardingUser() ? '_onboarding' : ''}`;
			// check if we have some match to `hashed_token` or `hashed_token_onboarding`
			if (eventKey === storageKey) {
				let eventValue = e.originalEvent.newValue;
				eventValue = Number(eventValue);
				if (isNaN(eventValue)) {
					eventValue = 0;
				}

				const token = getToken();
				const hashCodeToken = getHashCode(token);

				// What happens if getHashCode() is inconsistent?
				if (hashCodeToken !== getHashCode(token)) {
					throw new Error('getHashCode() does not return a consistent value.');
				}
				if (eventValue !== hashCodeToken) {
					scheduleNextLockCheck(0);
				}
			}
		});
	}

	attachTokens();
	watchForms();

	$(function () {
		if ((isObject(window.SESSION_USER) && window.SESSION_USER.id) || !isUndefined(window.sessionAdminUserId)) {
			scheduleNextLockCheck(15);
		}

		// Check the session timeout when window gains focus because setTimeout
		// is unreliable if browser is minified or computer sleeps.
		$(window).on('focus', function () {
			if (!canUseLocalStorage()) {
				return;
			}

			let timeoutData = window.localStorage.getItem('bhr_lock_times');
			if (timeoutData) {
				timeoutData = JSON.parse(timeoutData);
			}

			// Only try checking the server if we don't know how much time is remaining
			// on the session or if it may already have expired.
			const timeHasExpired = !timeoutData || new Date(timeoutData.sessionChecked + timeoutData.remaining) <= new Date();
			if (!isLockModalVisible() && timeHasExpired) {
				checkTimeout();
			}
		});
	});
})();

/****************************************************************************************************
 * This section handles session timeout.
 ****************************************************************************************************/

/**
 * Shows the Lock Modal
 *
 * @param {string} html
 * @param {string} urlPrefix
 */
function askLogin(urlPrefix, userId, username) {
	if (isLockModalVisible()) {
		return;
	}

	getLoginType()
		.catch((err) => {
			window.Rollbar.error('Failed to retrieve login type', err);
		})
		.then((resp) => {
			let loginType = resp.data.type;

			if (loginType !== PASSWORD_TYPE && !$('input#openidIdentity, input#samlLogin, input#openidConnectIss').length) {
				loginType = PASSWORD_TYPE;
			}

			const loginRequest = getLoginService(loginType);
			const mfaLoginTypes = [PASSWORD_TYPE, GOOGLE_TYPE];
			const primaryAction = () => {
				loginRequest({
					onSuccess: (resp) => {
						if (resp.result !== 'OK') {
							throw new Error('Failed to authenticate login');
						}

						handleLoginResponse(resp);

						if (mfaLoginTypes.includes(loginType)) {
							handleMfaLogin(userId);
						}
					},
					onError: showLockModalError,
				});
			};

			openLockModal({
				formType: loginType,
				urlPrefix,
				primaryAction,
				userId,
				username,
			});
		});
}

async function handleMfaLogin(userId) {
	try {
		const { data: { status } } = await doGetUserMfaData();
		if (
			status === 'setup' ||
			status === 'beforeRequired' ||
			(status === 'login' &&
				(await isRememberTokenValid(userId, getStoredRememberToken(userId))) ===
				MFA_JWT_REMEMBER_TOKEN_VALIDITY.INVALID)
		) {
			removeStoredRememberToken();
			window.location.reload();
		}
	} catch {
		console.error('Failed to handle MFA login');
	}
}

/**
 * Hides the LockModal if login is successful and updates CSRF token
 * Shows login error if login is not successful
 */
function handleLoginResponse(response, shouldReload) {
	if (shouldReload) {
		window.location.reload();
	} else if (response.result == 'OK') {
		closeLockModal();
		closeMessage();

		if (response.CSRFToken) {
			setToken(response.CSRFToken, isOnboardingUser());
			scheduleNextLockCheck(response.result.expiresIn);
		} else {
			/**
			 * Google endpoint won't return a CSRFToken in the response.
			 * We'll need to get the new token by calling checkTimeout.
			 */
			checkTimeout();
		}

		if (typeof window.unlockCallback === 'function') {
			window.unlockCallback();
		}

		/**
		 * Fire off a custom event so other places in the app can tie
		 * into successful login (e.g. websockets)
		 */
		document.dispatchEvent(new CustomEvent('AppSession:loggedIn'));
	} else {
		showLockModalError();
	}
}

/**
 * Shows error message for a failed login attempt
 */
function showLockModalError(err) {
	const response = err ? get(err, 'response.data') : null;

	let errorMessage = '';
	if ($('input#openidIdentity, input#samlLogin, input#openidConnectIss').length) {
		errorMessage = $.__('There is no BambooHR user for the account you chose.');
		window.setMessage('There is no BambooHR user for the account you chose.', 'error', { className: 'ignoreBlur' });
	} else if (response && response.error) {
		errorMessage = response.error;
	} else {
		errorMessage = $.__("Sorry, that password doesn't look right. Try it again?");
	}
	window.setMessage(errorMessage, 'error', { className: 'ignoreBlur' });

	const $passwordField = $('.js-passwordInput');
	$passwordField.val('').focus();
	if ($passwordField.length === 0) {
		$('.js-lock__sso').focus();
	}

	if (typeof window.addBrandedClass == 'function') {
		window.addBrandedClass();
	}
}

/**
 * Checks the server to see if the user's session is still valid and opens
 * the lock modal if the session is invalid.
 */
function checkTimeout() {
	let userId, urlPrefix, username;
	 if (isObject(window.SESSION_USER) && window.SESSION_USER.id && !window.SESSION_USER.isOfferLetterUser) {
		userId = window.SESSION_USER.id;
		urlPrefix = '/';
		username = window.SESSION_USER.username || '';
	} else if (isObject(window.SESSION_USER) && window.SESSION_USER?.isOfferLetterUser) {
		// Bypass the lock modal for offer letter users
		return false;
	} else if (typeof window.sessionAdminUserId != 'undefined') {
		/**
		 * Handles the GH Session
		 */
		handleGhSession()
		return false;
	} else {
		return false;
	}

	Ajax.get('/auth/check_session', { isOnboarding: isOnboardingUser() }).then(handleCheckSessionSuccess).catch(handleCheckSessionError);

	/**
	 * Handles response from a server request for valid session status.
	 */
	function handleCheckSessionSuccess(response) {
		const { CSRFToken, SessionMinutesLeft } = response.data;
		setToken(CSRFToken, isOnboardingUser());

		if (isLockModalVisible()) {
			closeLockModal();
		}

		scheduleNextLockCheck(SessionMinutesLeft);
	}


	function handleCheckSessionError(err) {
		const response = get(err, 'response');
		if (response && response.status === 401) {
			/**
			 * Status: Session is invalid
			 */
			askLogin(urlPrefix, userId, username);
			/**
			 * Fire off a custom event so other places in the app can tie
			 * into the session expiring (e.g. websockets)
			 */
			document.dispatchEvent(new CustomEvent('AppSession:expired'));
		} else {
			/**
			 * Handles failed server request for session status.
			 *
			 * This happens for SAML, when the user isn't logged in, it gets to
			 * oneLogin's login page. We can then send a request to lock.php
			 * and get back the inactive status. Can also be either a parse error on
			 * a non-saml login, or some other unknown error.
			 */
			scheduleNextLockCheck(1);
		}
	}
}

/**
 * Schedules the next time to check the server for a valid session
 * @param {number} minutesLeft Number of minutes to schedule into the future
 */
export function scheduleNextLockCheck(minutesLeft) {
	const millisecondsLeft = minutesLeft * 60 * 1000;
	if (nextLockCheckTimeout) {
		clearTimeout(nextLockCheckTimeout);
	}

	nextLockCheckTimeout = setTimeout(checkTimeout, millisecondsLeft);

	if (canUseLocalStorage()) {
		window.localStorage.setItem(
			'bhr_lock_times',
			JSON.stringify({
				sessionChecked: new Date().getTime(),
				remaining: millisecondsLeft,
			})
		);
	}
}

/**
 * Adds or removes CSRF token inputs to forms before they can be submitted.
 */
function watchForms() {
	const originalSubmit = HTMLFormElement.prototype.submit;

	/*
		This block seems redundant but it is necessary because invoking submit() on a HTMLFormElement will not trigger the 'submit' event.
		See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
	*/
	HTMLFormElement.prototype.submit = function (...args) {
		updateInput(this);
		originalSubmit.apply(this, args);
	};

	document.addEventListener('submit', function (e) {
		updateInput(e.target);
	});
}

/**
 * Returns boolean if the user it an onboarding user based on SESSION_USER
 * @returns {boolean} If the user is an onboarding user
 */
export function isOnboardingUser() {
	let { SESSION_USER } = window;
	if (isUndefined(SESSION_USER) || isNull(SESSION_USER)) {
		SESSION_USER = {};
	}
	const { isOnboardingUser = false } = SESSION_USER;
	if (isUndefined(isOnboardingUser) || isNull(isOnboardingUser) || !isBoolean(isOnboardingUser)) {
		return false;
	}
	return isOnboardingUser === true;
}

/**
 * Checks to see if lock modal is currently visible
 */
function isLockModalVisible() {
	try {
		return window.BambooHR.LockModal.isOpen();
	} catch (error) {
		// Return false if LockModal object is undefined, or if the call fails
		return false;
	}
}
