import React from 'react';
import { CoreImplementationBanner } from './core-implementation-banner';
import { CustomerOnboardingBanner } from './customer-onboarding-banner';
import { PayrollRegistrationBanner } from './payroll-registration-banner';
import { QuickStartBanner } from './quick-start-banner';
import { SetupSummaryBannerProps, SetupType } from './types';
import { removeBannerLocalStorage } from './setup-banners.utils';
import { useStepData } from './use-step-data';
import { useBannerData } from './use-banner-data';

export function SetupBanners() {
	const { bannerData, setBannerData } = useBannerData();
	const { stepData, setStepData } = useStepData(bannerData);

	const handleClose = () => {
		removeBannerLocalStorage();
		if (bannerData?.type === SetupType.CUSTOMER_ONBOARDING) {
			setBannerData(undefined);
		} else {
			setStepData(undefined);
		}
	};

	if (
		!bannerData ||
		(!stepData && bannerData?.type !== SetupType.CUSTOMER_ONBOARDING)
	) {
		return null;
	}

	let Banner;
	switch (bannerData?.type) {
		case SetupType.QUICKSTART:
			Banner = QuickStartBanner;
			break;
		case SetupType.PAYROLL:
			Banner = PayrollRegistrationBanner;
			break;
		case SetupType.CORE_IMPLEMENTATION:
			Banner = CoreImplementationBanner;
			break;
		case SetupType.CUSTOMER_ONBOARDING:
			Banner = CustomerOnboardingBanner;
			break;
		default:
			break;
	}

	if (!Banner) {
		return null;
	}

	const bannerProps: SetupSummaryBannerProps = {
		...bannerData,
		...stepData,
		onClose: handleClose,
	};

	return <Banner {...bannerProps} />;
}
