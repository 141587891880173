import { StandardModal, Button, TextButton, CardContentSpacing, Checkbox, Typography, Flex, makeStyles } from '@bamboohr/fabric';
import { PeopleCancelled54x54 } from '@bamboohr/grim';
import classNames from 'classnames';
import React, { FC, useState } from 'react';

import { BenchmarkingStatusUpdateModalProps } from '../types';

const useStyles = makeStyles(({spacing, palette: { gray }}) => ({
    well: {
        padding: spacing(1.5, 3, 1.5, 2),
        backgroundColor: gray[100],
        borderRadius: 2,
        marginTop: spacing(1.5),
    },
    bottomSection: {
        paddingLeft: spacing(3),
        maxHeight: 0,
        overflow: 'hidden',
        transition: '0.5s ease max-height',
    },
    bottomSectionVisible: {
        marginBottom: spacing(1),
        maxHeight: '100vh',
    },
    list: {
        marginLeft: spacing(3),
        listStyle: 'disc',
    },
}));

export const BenchmarkingOptOutModal: FC<React.PropsWithChildren<BenchmarkingStatusUpdateModalProps>> = ({isOpen, onRequestClose, isProcessing, onSubmit}) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    return (
        <StandardModal isOpen={isOpen} onRequestClose={onRequestClose} isProcessing={isProcessing}>
            <StandardModal.Body renderHeader={<StandardModal.Header title={$.__('Opt Out of Benchmarking')} />}>
                <StandardModal.Constraint spacingOverrides={{top: CardContentSpacing.LARGE, right: CardContentSpacing.NONE, bottom: CardContentSpacing.LARGE, left: CardContentSpacing.NONE}}>
                    <StandardModal.HeroHeadline icon={<PeopleCancelled54x54 />} text={$.__('Don’t include my company’s data in benchmarking')} />
                    <div className={classes.well}>
                        <Flex gap={1}>
                            <Checkbox checked={checked} onChange={p => setChecked(p.checked)} id='benchmarkOptOut' value='benchmarkOptOut' disabled={isProcessing}/>
                            <Typography component='label' variant='medium' fontWeight={600} htmlFor='benchmarkOptOut'>{$.__('I want to remove my company’s data from the BambooHR benchmarking dataset.')}</Typography>
                        </Flex>
                        <div className={classNames(classes.bottomSection, {[classes.bottomSectionVisible]: checked})}>
                            <Typography variant='medium' marginTop={1.5}>{$.__('Sure thing! Here’s what will happen:')}</Typography>
                            <ul className={classes.list}>
                                <Typography component='li' variant='medium'>{$.__('You will no longer have access to benchmarking features.')}</Typography>
                                <Typography component='li' variant='medium'>{$.__('Your company’s data will no longer be included or aggregated in the benchmarking pool.')}</Typography>
                                <Typography component='li' variant='medium'>{$.__('You can opt back in at any point and regain access to benchmarking.')}</Typography>
                            </ul>
                        </div>
                    </div>
                </StandardModal.Constraint>
                <StandardModal.Footer actions={[<Button disabled={!checked} key='submit' processing={isProcessing} onClick={onSubmit}>{$.__('Opt Out')}</Button>, <TextButton key='cancel' onClick={onRequestClose} processing={isProcessing}>{$.__('Cancel')}</TextButton>]} />
            </StandardModal.Body>
        </StandardModal>
    );
};
