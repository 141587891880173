import React, { ReactElement } from 'react';

import { IconV2 } from '@bamboohr/fabric';

import { useStyles } from './styles';

export type NoResultsProps = {
	searchText: string;
};

export const NoResults = (props: NoResultsProps): ReactElement => {
	const { searchText } = props;
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<IconV2 name="magnifying-glass-regular" size={28} />
			<div className={classes.text}>
				{$.__('No matches for %s', `"${searchText}"`)}
			</div>
		</div>
	);
};
