import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { setErrorMessage } from './set-error-message';

export type SetApiErrorMessage = (error: AxiosError) => void;

export interface UseSetApiErrorMessage {
	setApiErrorMessage: SetApiErrorMessage;
	setErrorMessage: (message: string) => void;
}

export const useSetApiErrorMessage = (): UseSetApiErrorMessage => {
	const setApiErrorMessage = useCallback(
		(error: AxiosError) => {
			switch (error.response?.status) {
				case 403:
					setErrorMessage(
						$.__('You do not have sufficient permissions for this.')
					);
					break;
				case 404:
					setErrorMessage($.__('Whoops... We could not find that.'));
					break;
				default:
					setErrorMessage($.__('Oops. Something went wrong. Please try again.'));
			}
		},
		[setErrorMessage, $.__],
	);

	return { setApiErrorMessage, setErrorMessage };
};
