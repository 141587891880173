import { camelCase } from 'lodash';

export function camelCaseKeys(
	obj: Record<string, unknown>,
): Record<string, unknown> {
	return Object.keys(obj).reduce((memo, key) => {
		memo[camelCase(key)] = obj[key];
		return memo;
	}, {});
}
