import {
	EmployeeResultData,
	SearchResultData,
	SearchResultType,
} from '../../types';

export function isEmployeeType(
	data: SearchResultData,
): data is EmployeeResultData {
	return data.type === SearchResultType.Employee;
}
