import { MenuList, IconV2, BodyText } from '@bamboohr/fabric';
import React, { FC, useState } from 'react';
import { NoResults } from '../../no-results';
import { Result } from './result';
import { useGroupLabel } from './use-group-label';
import { redirect } from './utils';
import { getDestinationUrl } from '../etc';
import { SearchResultData, SearchResultType } from '../types';

export type ResultsProps = {
	data: Array<SearchResultData>;
	searchText: string;
	keyboardTarget: React.RefObject<HTMLInputElement>;
};

export const Results: FC<React.PropsWithChildren<ResultsProps>> = (props) => {
	const { data, keyboardTarget, searchText } = props;
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const { getLabel } = useGroupLabel();

	const iconMap = {
		[SearchResultType.Employee]: 'user-group-solid',
		[SearchResultType.File]: 'file-lines-solid',
		[SearchResultType.JobCandidate]: 'id-badge-solid',
		[SearchResultType.JobOpening]: 'thumbtack-solid',
		[SearchResultType.Report]: 'chart-pie-simple-solid',
	};

	const getRenderGroup = (group: string) => {
		return (
			<BodyText
				color="neutral-extra-strong"
				icon={
					<IconV2
						color="neutral-extra-strong"
						name={iconMap[group]}
						size={12}
					/>
				}
				size="extra-small"
				weight="semibold"
			>
				{getLabel(group)}
			</BodyText>
		);
	};

	return data.length ? (
		<MenuList
			activeIndex={activeIndex}
			getGroup={(index) => data[index].type}
			keyboardTarget={keyboardTarget?.current ?? undefined}
			onActive={(index) => setActiveIndex(index)}
			onSelect={(index) => {
				const url = getDestinationUrl(data[index]);

				if (url) {
					redirect(url);
				}
			}}
			renderGroup={(group) => getRenderGroup(group)}
			renderItem={(index, menuItemProps) => (
				<Result
					data={data[index]}
					menuItemProps={menuItemProps}
					searchText={searchText}
				/>
			)}
			total={data.length}
		/>
	) : (
		<NoResults searchText={searchText} />
	);
};
