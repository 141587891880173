import React, { Fragment, useState } from 'react';
import { Banner, BannerLink, BodyText, TextButton } from '@bamboohr/fabric';
import { CompleteNewHirePacketProps } from './types';
import { DisabledForPreviewingModal } from './disabled-for-previewing-modal';

export function CompleteNewHirePacketBanner(data: CompleteNewHirePacketProps) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Fragment>
			<Banner icon="hand-wave-solid">
				<BodyText inline>
					{$.__(
						'Hi %s, you still need to complete your New Hire Packet.',
						data.prefFirstName,
					)}
				</BodyText>{' '}
				{window.GLOBAL_IS_ASSUMED_USER ? (
					<TextButton dark inline onClick={() => setIsOpen(true)}>
						{$.__('Complete it now') + ' »'}
					</TextButton>
				) : (
					<BannerLink href="/employees/new_hire_packet_login.php">
						{$.__('Complete it now')} »
					</BannerLink>
				)}
			</Banner>
			<DisabledForPreviewingModal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
			/>
		</Fragment>
	);
}
