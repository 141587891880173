import { SearchResultData } from '../../types';

export function addPhotoUrl(
	data: SearchResultData & Record<string, unknown>,
	photoUrlMap: Record<string, string>,
): SearchResultData & { photoUrl: string } {
	return {
		...data,
		photoUrl: photoUrlMap[data.id as string] || '',
	};
}
