import React from 'react';
import { ActionBanner, Button, IconButton, IconV2 } from '@bamboohr/fabric';
import { SetupSummaryBannerProps } from './types';

export function CustomerOnboardingBanner(props: SetupSummaryBannerProps) {
	const { biId, buttonText, iconName, onClose, primaryUrl, subtitle, title } =
		props;
	const bannerIcon = (
		<IconV2 name={iconName ? iconName : 'gauge-high-regular'} size={60} />
	);

	return (
		<ActionBanner
			actions={[
				<Button
					dark
					data-bi-id={
						biId
							? `${biId}-customerOnboardingSummaryBanner-backToCustomerOnboarding`
							: null
					}
					href={primaryUrl}
					key="primary"
					type="button"
				>
					{buttonText}
				</Button>,
				<IconButton
					ariaLabel={$.__('Close Customer Onboarding banner.')}
					color="secondary"
					dark
					icon={'xmark-solid'}
					key="close"
					onClick={onClose}
					type="button"
				/>,
			]}
			ariaLabel={$.__('Customer Onboarding banner.')}
			biId={biId}
			image={bannerIcon}
		>
			<ActionBanner.Title subTitle={subtitle} title={title} />
		</ActionBanner>
	);
}
