import { matchPath } from 'react-router-dom';

export const pathMatchesHeaderLink = (linkUrl = '', pathName = '') => {
	const [, feature] = /\/?app\/([^/]+)/gi.exec(pathName) || [];

	let linkPathName = linkUrl;
	try {
		linkPathName = new URL(linkUrl).pathname;
	} catch (e) {
		linkPathName = linkUrl;
	}

	return !!(feature && matchPath(linkPathName, `/app/${feature}/:subpath?`));
};
