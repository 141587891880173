import React, { ReactNode, useState } from 'react';
import { GlobalNavigation, Tooltip } from '@bamboohr/fabric';
import { SitePageData } from '../types';
import { linkConfigs } from './constants';

function isNavItemActive(currentPage, id) {
	if (currentPage === '') {
		const url = window.location.href;
		return url.includes(`com/app/${id}`);
	}

	return currentPage === id;
}

function NavItem({
	isActive,
	page,
	navigationIsOpen,
}: {
	isActive: boolean;
	page: SitePageData;
	navigationIsOpen: boolean;
}) {
	const { biId, id, subtitle, title, url } = page;
	const { activeIcon, ariaLabel, divider, external, icon, label } =
		linkConfigs[id] || {};
	const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

	return (
		<>
			{!navigationIsOpen && (
				<Tooltip
					anchorEl={anchorEl}
					content={ariaLabel || title}
					enterDelay={150}
					placement="right"
				/>
			)}
			<GlobalNavigation.Link
				active={isActive}
				activeIcon={activeIcon}
				ariaLabel={ariaLabel}
				biId={biId}
				containerRef={(el) => setAnchorEl(el)}
				divider={divider}
				external={external}
				href={url}
				icon={icon}
				label={label || title}
				note={subtitle}
			/>
		</>
	);
}

export function getGlobalNavigationLinks({
	pages = [],
	currentPage,
	navigationIsOpen,
}: {
	pages: SitePageData[];
	currentPage: string;
	navigationIsOpen: boolean;
}): ReactNode[] {
	return pages.map((page) => (
		<NavItem
			isActive={isNavItemActive(currentPage, page.id)}
			key={page.id}
			navigationIsOpen={navigationIsOpen}
			page={page}
		/>
	));
}
