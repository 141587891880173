import React from 'react';
import { ActionBanner, Button, Avatar } from '@bamboohr/fabric';
import { PreviewAsProps } from './types';

export function PreviewAsBanner(previewData: PreviewAsProps) {
	const actions = [
		<Button
			color="primary"
			href={`/settings/permissions/?test=0&uri=${previewData.requestUri}`}
			key="Done Previewing"
			type="button"
		>
			<span>&laquo; {$.__('Done Previewing')}</span>
		</Button>,
	];
	return (
		<ActionBanner
			actions={actions}
			ariaLabel={$.__(
				`This is what BambooHR looks like for %s`,
				previewData.previewName,
			)}
			image={
				<Avatar
					alt="Avatar for User"
					// @ts-expect-error TODO: this size is no longer supported
					size={60}
					src={previewData.employeeImageUrl}
				/>
			}
		>
			<ActionBanner.Title
				superTitle={$.__('This is what BambooHR looks like for')}
				title={previewData.previewName}
			/>
		</ActionBanner>
	);
}
