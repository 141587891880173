import { makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ constructs, palette, typography }) => ({
	inboxButton: {
		'&:hover': {
			fill: palette.primary.main,
		},
		display: 'flex',
		marginLeft: '20px',
	},
	inboxWrapper: {
		height: '24px',
		width: '26px',
	},
	countContainer: {
		position: 'absolute',
		height: '40px',
		width: '40px',
		'&:hover': {
			'& $count': {
				borderColor: palette.gray[100],
			},
		},
	},
	count: {
		backgroundColor: palette.primary.main,
		position: 'absolute',
		right: '5px',
		top: '-5px',
		fontSize: '10px',
		border: '2px solid transparent',
		borderColor: constructs.border.neutral.forcedWhite,
		color: constructs.text.neutral.forcedWhite,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		fontWeight: typography.fontWeightHeavy,
		width: '22px',
		height: '22px',
		textIndent: 0,
	},
	activeCount: {
		borderColor: palette.gray[100],
	},
}));
