import React, { useState } from 'react';
import { GlobalNavigation } from './global-navigation';
import { SiteData } from '../types';

export function GlobalNavigationWidget(site: SiteData) {
	const initialNavOpen = window.localStorage.getItem('globalNavigationOpen') === 'true';
	const [navOpen, setNavOpen] = useState(initialNavOpen);

	return (
		<GlobalNavigation
			navigationIsOpen={navOpen}
			onToggle={() => {
				setNavOpen((isOpen) => {
					if (!isOpen) {
						document
							.getElementById('js-GlobalNavigation')
							?.classList?.add('GlobalNavigation--open');
					} else {
						document
							.getElementById('js-GlobalNavigation')
							?.classList?.remove('GlobalNavigation--open');
					}
					window.localStorage.setItem('globalNavigationOpen', String(!isOpen));
					return !isOpen;
				});
			}}
			siteData={site}
		/>
	);
}
