import {
	JobOpeningResultData,
	SearchResultData,
	SearchResultType,
} from '../../types';

export function isJobOpeningType(
	data: SearchResultData,
): data is JobOpeningResultData {
	return data.type === SearchResultType.JobOpening;
}
