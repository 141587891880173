import React, { FC } from 'react';
import { JobCandidateResult } from './job-candidate-result';
import { EmployeeResult } from './employee-result';
import { FileResult } from './file-result';
import { JobOpeningResult } from './job-opening-result';
import { ReportResult } from './report-result';
import {
	isEmployeeType,
	isFileType,
	isJobCandidateType,
	isJobOpeningType,
	isReportType,
} from '../../etc';
import { SearchResultData } from '../../types';
import { MenuItem, type MenuItemProps } from '@bamboohr/fabric';

export type ResultItemProps = {
	data: SearchResultData;
	menuItemProps: MenuItemProps;
	searchText: string;
};

export const Result: FC<React.PropsWithChildren<ResultItemProps>> = (props) => {
	const { data, menuItemProps, searchText } = props;

	if (isEmployeeType(data)) {
		return (
			<MenuItem
				{...menuItemProps}
				alignItems="center"
				muted={data.status === 'Inactive'}
			>
				<EmployeeResult data={data} searchText={searchText} />
			</MenuItem>
		);
	}
	if (isJobCandidateType(data)) {
		return (
			<MenuItem {...menuItemProps}>
				<JobCandidateResult data={data} />
			</MenuItem>
		);
	}
	if (isJobOpeningType(data)) {
		return (
			<MenuItem {...menuItemProps}>
				<JobOpeningResult data={data} />
			</MenuItem>
		);
	}
	if (isReportType(data)) {
		return (
			<MenuItem {...menuItemProps}>
				<ReportResult data={data} />
			</MenuItem>
		);
	}
	if (isFileType(data)) {
		return (
			<MenuItem {...menuItemProps}>
				<FileResult data={data} />
			</MenuItem>
		);
	}

	return (
		<MenuItem {...menuItemProps}>
			<div>{JSON.stringify(data)}</div>
		</MenuItem>
	);
};
