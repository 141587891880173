import { Avatar, MenuItemAvatar, MenuItemText } from '@bamboohr/fabric';
import React, { FC, Fragment } from 'react';
import { EmployeeResultData } from '../../../types';
import { getDisplayName } from './etc/get-display-name';

export type EmployeeResultProps = {
	data: EmployeeResultData;
	searchText: string;
};

export const EmployeeResult: FC<React.PropsWithChildren<EmployeeResultProps>> = (props) => {
	const {
		data: { photoUrl },
		searchText,
	} = props;

	return (
		<Fragment>
			<MenuItemAvatar>
				<Avatar src={photoUrl} />
			</MenuItemAvatar>
			<MenuItemText primary={getDisplayName(props.data, searchText)} />
		</Fragment>
	);
};
