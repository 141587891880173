import React from 'react';
import { Flex, IconV2 } from '@bamboohr/fabric';

/**
 * These keys correspond to the long-standing configs found in main/app/includes/Utils/twig_auth_helper.php
 * We now have the need for complex (React) content in these links, so this allows us to enhance what's
 * being sent from main. Eventually we may want to move those over here to a single place.
 */
export const linkConfigs = {
	home: {
		activeIcon: <IconV2 name="house-solid" size={24} />,
		icon: <IconV2 name="house-regular" size={24} />,
	},
	myInfo: {
		activeIcon: <IconV2 name="circle-user-solid" size={24} />,
		icon: <IconV2 name="circle-user-regular" size={24} />,
	},
	employees: {
		activeIcon: <IconV2 name="user-group-solid" size={24} />,
		icon: <IconV2 name="user-group-regular" size={24} />,
	},
	hiring: {
		activeIcon: <IconV2 name="id-badge-solid" size={24} />,
		icon: <IconV2 name="id-badge-regular" size={24} />,
	},
	reports: {
		activeIcon: <IconV2 name="chart-pie-simple-solid" size={24} />,
		icon: <IconV2 name="chart-pie-simple-regular" size={24} />,
	},
	files: {
		activeIcon: <IconV2 name="file-lines-solid" size={24} />,
		icon: <IconV2 name="file-lines-regular" size={24} />,
	},
	payroll: {
		activeIcon: <IconV2 name="circle-dollar-solid" size={24} />,
		icon: <IconV2 name="circle-dollar-regular" size={24} />,
	},
	payrollForTrialAccount: {
		activeIcon: <IconV2 name="circle-dollar-solid" size={24} />,
		icon: <IconV2 name="circle-dollar-regular" size={24} />,
	},
	quickbooksOnlinePayroll: {
		activeIcon: <IconV2 name="circle-dollar-solid" size={24} />,
		ariaLabel: $.__('Payroll Powered by QuickBooks'),
		divider: true,
		external: true,
		icon: <IconV2 name="circle-dollar-regular" size={24} />,
		label: (
			<Flex alignItems="center" gap={0.5}>
				{$.__('Payroll')}
				{
					' ' /* need this space to make visual text match ariaLabel to satisfy a11y checker */
				}
				<IconV2 name="arrow-up-right-from-square-solid" size={10} />
			</Flex>
		),
	},
	benefits: {
		activeIcon: <IconV2 name="heart-pulse-solid" size={24} />,
		icon: <IconV2 name="heart-pulse-regular" size={24} />,
	},
};
